<template>
     <PreLoadingOverlay />
     <HeaderAfterLogin
          v-if="token"
          :onPlay="onPlay"
     />
     <HeaderGuest v-else />
     <div
          v-if="token"
          :class="[
               menuAuth && !onPlay ? 'ml-0 md:ml-64' : 'ml-0',
               !onPlay
                    ? 'px-3 sm:px-5 md:px-10 lg:px-12 xl:px-14 2xl:px-18'
                    : ''
          ]"
          class="flex justify-center transition-all duration-300 ease-in-out"
     >
          <div
               :class="
                    onPlay
                         ? 'w-full mt-4'
                         : 'mt-16 w-full md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl'
               "
               class="transition-all duration-300 ease-in-out"
          >
               <router-view />
          </div>
     </div>
     <div
          v-else-if="!checkGuestPage"
          :class="[menuGuest ? 'ml-0 md:ml-64' : 'ml-0']"
          class="flex justify-center px-5 sm:px-8 md:px-10 lg:px-12 xl:px-14 2xl:px-18 transition-all duration-300 ease-in-out"
     >
          <div
               class="mt-16 w-full md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl transition-all duration-300 ease-in-out"
          >
               <router-view />
          </div>
     </div>
     <div
          v-else-if="checkGuestPage"
          :class="[menuGuest ? 'ml-0 md:ml-64' : 'ml-0']"
     >
          <div class="mt-16">
               <router-view />
          </div>
     </div>
     <FooterLayout v-if="!onPlay" />
</template>

<script>
import HeaderGuest from "components/navigation/guest/index.vue";
import HeaderAfterLogin from "components/navigation/afterlogin/index.vue";
import FooterLayout from "components/footer/index.vue";
import { ref, computed, onMounted, watch } from "vue";
import { authStore } from "store/auth.js";
import eventBus from "plugins/event.js";
import _ from "underscore";
import Cookies from "js-cookie";
import { useRoute } from "vue-router";
import PreLoadingOverlay from "components/loading/preLoadingOverlay.vue";
import { utilitiesStore } from "store/utilities.js";
import { bonusStore } from "store/bonus.js";
import { useWebSocket } from "services/ws.js";
import { ioSocketStore } from "store/io.socket.js";
import { useIOSocketInit } from "composables/use-socket-init.js";

export default {
     components: {
          HeaderGuest,
          HeaderAfterLogin,
          FooterLayout,
          PreLoadingOverlay
     },
     setup() {
          const route = useRoute();
          const useAuthStore = authStore();
          const useUtilitiesStore = utilitiesStore();
          const useBonusStore = bonusStore();
          const useIOSocketStore = ioSocketStore();

          const { initSocket } = useWebSocket();
          const { onWatchResponseReceivedIO, onWatchGetGameIO } =
               useIOSocketInit();

          const checkGuestPage = ref(false);

          const onPlay = ref(false);

          const token = computed(() => {
               return useAuthStore.token;
          });

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const isEmailVerified = computed(() => {
               return useAuthStore.isEmailVerified;
          });

          const loading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const menuAuth = computed(() => {
               return useUtilitiesStore.menuAuth;
          });

          const menuGuest = computed(() => {
               return useUtilitiesStore.menuGuest;
          });

          const socket = computed(() => {
               return useIOSocketStore.socket;
          });

          const checkAuthPath = () => {
               onPlay.value = route.name == "play";
               if (onPlay.value) {
                    useUtilitiesStore.setMenuAuth(false);
               }

               let path = window.location.pathname;
               checkGuestPage.value = ["signin", "register", "forgot"].includes(
                    path.replaceAll("/", "")
               );
          };

          onMounted(() => {
               checkAuthPath();
               if (!loading.value) {
                    return;
               }
               useUtilitiesStore.preLoading(true);
               useUtilitiesStore.disableScroll();

               setTimeout(() => {
                    useUtilitiesStore.preLoading(false);
                    useUtilitiesStore.enableScroll();
               }, 3000);
          });

          watch(token, (newVal) => {
               if (newVal) {
                    menuAuth.value = true;
               } else {
                    menuGuest.value = true;
               }
          });

          watch(isUser, (newVal) => {
               if (newVal) {
                    initSocket();
               }

               if (newVal?.meta) {
                    if (route.name == "home") {
                         if (route.query.modal == "dailybonus") {
                              return;
                         }

                         let filter = _.filter(newVal.meta, function (params) {
                              return params["meta_key"] == "welcome_bonus";
                         });

                         if (filter.length == 0) {
                              setTimeout(async () => {
                                   await useBonusStore.getWelcome();
                              }, 0);

                              setTimeout(() => {
                                   if (Cookies.get("welcome") == null) {
                                        if (isEmailVerified.value) {
                                             // var currentTime = new Date();
                                             eventBus.emit("open:welcomebonus");
                                             // Cookies.set("welcome", true, {
                                             //      expires: new Date(
                                             //           currentTime.getTime() +
                                             //                60 * 60 * 1000
                                             //      ),
                                             // });
                                        }
                                   }
                              }, 3000);
                         }
                    }

                    let filter = _.filter(newVal.meta, function (params) {
                         return params["meta_key"] == "free_gc_topup";
                    });

                    if (filter.length >= 1) {
                         if (!loading.value) {
                              useBonusStore.claimFreeGC();
                         }
                    }
               }
          });

          watch(loading, (newVal) => {
               if (!newVal) {
                    if (isUser?.value?.meta) {
                         let filter = _.filter(
                              isUser.value.meta,
                              function (params) {
                                   return params["meta_key"] == "free_gc_topup";
                              }
                         );

                         if (filter.length >= 1) {
                              useBonusStore.claimFreeGC();
                         }
                    }
               }
          });

          watch(route, (newVal) => {
               if (newVal) {
                    checkAuthPath();
               }
          });

          watch(socket, (newVal) => {
               if (newVal) {
                    console.log(newVal);
                    onWatchResponseReceivedIO();
                    onWatchGetGameIO();
               }
          });

          return {
               token,
               menuAuth,
               menuGuest,
               checkAuthPath,
               checkGuestPage,
               onPlay
          };
     }
};
</script>
