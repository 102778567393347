import { io } from "socket.io-client";
import { authStore } from "store/auth.js";
import { ioSocketStore } from "store/io.socket.js";
import { computed } from "vue";
import Cookies from "js-cookie";

// let socket;

export const useWebSocket = () => {
     const useAuthStore = authStore();
     const useIOSocketStore = ioSocketStore();

     const token = computed(() => {
          return useAuthStore.token;
     });

     const username = computed(() => {
          return useAuthStore.username;
     });

     const base =
          import.meta.env.VITE_ENV == "production"
               ? "wss://web-socket.moozi.com"
               : "wss://moozi-socket.cobaaja.xyz";

     const initSocket = () => {
          let socket = null;

          if (!socket) {
               socket = io(base, {
                    transports: ["websocket"],
                    query: {
                         token: token.value,
                         username: username.value
                              ? username.value
                              : Cookies.get("username")
                    }
               });

               socket.on("connect", () => {
                    console.log("Connected with socket id:", socket.id);
                    socket.emit(
                         "subscribeToPrivateChannel",
                         "user." +
                              (username.value
                                   ? username.value
                                   : Cookies.get("username"))
                    );
               });
          }

          useIOSocketStore.setSocket(socket);
     };

     return {
          initSocket
     };
};
